import CustomFreeSortPlugin from "../../customPlugin/customFreeSort";

/**
 * Specifies the parameters needed for the quiz trials
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Score} score The Score instance used to keep track of the user's score within the mission
 * @param {string} backgroundImage The background image
 * @param {Array} stimuli An array of images the user has to sort
 * @returns
 */
export function buildFreeSortQuizTrial(jsPsych, score, backgroundImage, stimuli) {
  return {
    type: CustomFreeSortPlugin,
    stim_width: 100,
    stim_height: 100,
    // NOTE These are hard coded to match the size of the background images
    sort_area_width: 1134,
    sort_area_height: 659,
    sort_area_shape: "square",
    backgroundImage,
    stimuli,
    on_finish: (data) => {
      score.assignScore(data);
    },
  };
}
