import CustomInstructionsResponsePlugin from "../../customPlugin/customInstructions";
import { instructionImage } from "../../lib/utils";

/**
 * Displays the mission feedback screen as an instructions trial
 * @param {string} image The image to display
 */
export function buildAchievementsTrial(image) {
  return {
    type: CustomInstructionsResponsePlugin,
    data: { name: "achievements" },
    pages: [instructionImage(image)],
    key_forward: "Slash",
    key_backward: "KeyZ",
  };
}
