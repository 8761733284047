/**
 * Custom keyboard response utility that mimics jsPsych's KeyboardListenerAPI
 * that allows for key code keyboard listener
 */
export class CustomKeyboardResponse {
  /**
   // * @param {function} getRootElement - Function that returns the DOM element to attach listeners to
   * @param {boolean} areResponsesCaseSensitive - Whether responses are case sensitive
   * @param {number} minimumValidRt - Minimum reaction time to consider a response valid
   */
  constructor(
    // getRootElement,
    areResponsesCaseSensitive = false,
    minimumValidRt = 0
  ) {
    // this.getRootElement = getRootElement;
    this.areResponsesCaseSensitive = areResponsesCaseSensitive;
    this.minimumValidRt = minimumValidRt;

    this.listeners = new Set();
    this.heldKeys = new Set();
    this.areRootListenersRegistered = false;
  }

  /**
   * If not previously done and `this.getRootElement()` returns an element, adds the root key
   * listeners to that element.
   */
  registerRootListeners() {
    if (this.areRootListenersRegistered) {
      return;
    }
    //TODO: is adding the eventListener to the whole document fine?
    document.addEventListener("keydown", this.rootKeydownListener.bind(this));
    document.addEventListener("keyup", this.rootKeyupListener.bind(this));
    this.areRootListenersRegistered = true;
  }

  /**
   * Converts a key to lowercase if case-insensitive mode is enabled
   * @param {string} key - The key to potentially convert
   * @returns {string} - The key, potentially converted to lowercase
   */
  toLowerCaseIfInsensitive(key) {
    return this.areResponsesCaseSensitive ? key : key.toLowerCase();
  }

  /**
   * Handles keyup events to track which keys are no longer being held
   * @param {KeyboardEvent} e - The keyboard event
   */
  rootKeyupListener(e) {
    const key = this.toLowerCaseIfInsensitive(e.key);
    this.heldKeys.delete(key);
  }

  /**
   * Handles keydown events to track which keys are no longer being held
   * @param {KeyboardEvent} e - The keyboard event
   */
  rootKeydownListener(e) {
    for (const listener of Array.from(this.listeners)) {
      listener(e);
    }

    const key = this.areResponsesCaseSensitive ? e.key : e.key.toLowerCase();
    this.heldKeys.add(key);
  }

  /**
   * Checks if a key response is valid based on criteria
   * @param {string} key - The key that was pressed
   * @param {string} keyCode - The keyCode associated with key that was pressed
   * @param {Array<string> | string} validResponses - Array of valid responses
   * @param {boolean} allowHeldKey - Whether held keys should trigger responses
   * @returns {boolean} - Whether the response is valid
   */
  isResponseValid(key, keyCode, validResponses, allowHeldKey) {
    // If the key is currently being held down and we don't allow held keys
    if (!allowHeldKey && this.heldKeys.has(key)) {
      return false;
    }

    if (validResponses === "ALL_KEYS") {
      return true;
    }
    if (validResponses === "NO_KEYS") {
      return false;
    }
    return validResponses.includes(keyCode);
  }

  /**
   * Custom implementation of getKeyboardResponse
   * @param {Object} options - Configuration options
   * @param {function} options.callback_function - Function to call when a valid key is pressed
   * @param {Array<string> | string} [options.valid_responses="ALL_KEYS"] - Array of valid key responses
   // * @param {string} [options.rt_method='date'] - Method to measure reaction time
   * @param {boolean} [options.persist=false] - Whether to continue listening after a response
   // * @param {AudioContext} [options.audio_context] - Audio context for RT measurement
   // * @param {number} [options.audio_context_start_time] - Start time for audio context RT
   * @param {boolean} [options.allow_held_key=false] - Whether to allow held keys
   * @param {number} [options.minimum_valid_rt] - Minimum RT to consider valid
   * @returns {Object} - Listener object that can be used to cancel the response
   */
  getKeyboardResponse({
    callback_function,
    valid_responses = "ALL_KEYS",
    // rt_method = 'date',
    persist = false,
    // audio_context,
    // audio_context_start_time,
    allow_held_key = false,
    minimum_valid_rt,
  }) {
    this.registerRootListeners();
    const startTime = performance.now();

    // Create the listener function
    const listener = (e) => {
      const key = this.areResponsesCaseSensitive ? e.key : e.key.toLowerCase();
      const keyCode = e.code;
      const rt = Math.round(performance.now() - startTime);
      // Check if response is too quick
      if (rt < minimum_valid_rt) {
        return;
      }

      // Check if key is valid
      const isValid = this.isResponseValid(key, keyCode, valid_responses, allow_held_key);

      if (isValid) {
        e.preventDefault();

        if (!persist) {
          this.cancelKeyboardResponse(listener);
        }

        // Call the callback with all the information you need
        callback_function({
          key: key,
          rt: rt,
          keyCode: keyCode,
        });
      }
    };

    // Add listener to the set
    this.listeners.add(listener);
    return listener;
  }

  /**
   * Cancel a specific keyboard response listener
   * @param {Object|function} listener - The listener to cancel
   */
  cancelKeyboardResponse(listener) {
    this.listeners.delete(listener);
  }

  /**
   * Cancel all keyboard response listeners
   */
  cancelAllKeyboardResponses() {
    this.listeners.clear();
  }

  /**
   * Compare two keys accounting for case sensitivity settings
   * @param {string|null} key1 - First key to compare
   * @param {string|null} key2 - Second key to compare
   * @returns {boolean} - Whether the keys are equal
   */
  compareKeys(key1, key2) {
    if (key1 === null || key2 === null) {
      return key1 === key2;
    }

    return this.toLowerCaseIfInsensitive(key1) === this.toLowerCaseIfInsensitive(key2);
  }
}
