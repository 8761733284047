import CustomHtmlKeyboardResponsePlugin from "../../customPlugin/customHtmlKeyboardResponse";

import { KEY_CODES } from "../../gearshiftGame/constants";
import { Game } from "../../gearshiftGame/game";
import { Score } from "../../gearshiftGame/score";
import { createStimulus } from "../../gearshiftGame/utils";
import { getGearshiftMissionConfig, instructionImage } from "../../lib/utils";
import { buildAchievementsTrial } from "../trials/achievements";
import { buildFeedbackTrial } from "../trials/feedback";
import { buildInstructionsTrial } from "../trials/instructions";
import { buildSkipMissionProcedure } from "./skipMission";
/**
 * @type {number}
 * @description The current mission
 */
const MISSION_NUMBER = 2;

/**
 * @type {Score}
 * @description The user's score
 * NOTE: Used here so we don't have to pass it through every single function
 */
let SCORE;

/**
 * Builds the first mission of the game
 * @param {Object} jsPsych The jsPsych instance being used to run the task
 * @param {Object} metadata The global metadata of the experiment
 * @returns The timeline object for the first mission
 */
export async function buildMissionTwo(jsPsych, metadata) {
  let config;
  try {
    config = await getGearshiftMissionConfig(jsPsych, metadata, MISSION_NUMBER);
  } catch (e) {
    console.error(`Unable to load mission config file for mission ${MISSION_NUMBER}`);
  }

  // No config file was given for mission 1, skip this mission (no file given or failed to load the file)
  if (!config) return buildSkipMissionProcedure(MISSION_NUMBER);

  // Config file given - build the mission!
  console.log("Mission Two Configuration:", config);

  // Initialize variables shared across the mission
  const { missionLives, missionMinScore, missionMaxCrash, missionNumTrials, trials, thisFile } =
    config;
  SCORE = new Score(
    jsPsych,
    missionLives, // # of lives to start the mission with
    metadata.rewardReductionByReset // Amount to reduce score by when the user runs out of lives
  );

  // Build the timeline from the missionConfig trials array
  const missionTwoTimeline = trials.map((trialConfig) => {
    const stimulus = createStimulus(MISSION_NUMBER, missionNumTrials, trialConfig);
    switch (trialConfig.trialType) {
      case "INSTRUCTION":
        // NOTE: Instructions trial is the first trial of the mission
        return buildMissionTwoInstructions(jsPsych, trialConfig);
      case "TASK":
        return buildMissionTwoTask(jsPsych, metadata, trialConfig, stimulus);
      case "MESSAGE":
        return buildMissionTwoMessage(jsPsych, metadata, trialConfig, stimulus);
      case "FEEDBACK":
        // NOTE: Feedback trial is the last trial of the mission
        return buildMissionTwoFeedback(jsPsych, trialConfig, missionMinScore, missionMaxCrash);
      case "SURVEY":
        return buildMissionTwoSurvey(jsPsych, metadata, trialConfig, stimulus);
      default:
        throw new Error("An unknown trial type was found:", trialConfig.trialType);
    }
  });

  return {
    data: {
      missionNumber: MISSION_NUMBER,
      missionFile: thisFile,
      missionNumTrials,
      skipped: false,
    },
    save_trial_parameters: { stimulus: false },
    timeline: missionTwoTimeline,
  };
}

/**
 * Builds the trial for displaying the instructions for mission two
 * @param {Object} jsPsych The jsPsych instance being used to run the task
 * @returns
 */
function buildMissionTwoInstructions(jsPsych, trialConfig) {
  return {
    data: { trialConfig },
    ...buildInstructionsTrial(jsPsych, [
      instructionImage("assets/images/instructions/m2/instructions 2.1.svg"),
      instructionImage("assets/images/instructions/m2/instructions 2.2.svg"),
      instructionImage("assets/images/instructions/m2/instructions 2.3.svg"),
      instructionImage("assets/images/instructions/m2/instructions 2.4.svg"),
      instructionImage("assets/images/instructions/m2/instructions 2.5.svg"),
      instructionImage("assets/images/instructions/m2/instructions 2.6.svg"),
    ]),
    on_finish: (data) => {
      SCORE.assignScore(data);
    },
  };
}

/**
 * Builds the trial needed for mission two's task
 * @param {Object} jsPsych The jsPsych instance being used to run the task
 * @param {Object} metadata The global metadata of the experiment
 * @param {Object} missionConfig The task configuration used to build the mission
 * @param {Object} trialConfig The task configuration used to build the trial
 * @param {Object} additionalConfig Additional configuration needed to
 * @returns
 */
// TODO: Need to add data.name to these trials?
function buildMissionTwoTask(jsPsych, metadata, trialConfig, stimulus) {
  const newGame = new Game(jsPsych, metadata, SCORE, trialConfig);

  async function playAudio(audioFile) {
    try {
      // get the preloaded audio buffer, returns promise
      const audioBuffer = await jsPsych.pluginAPI.getAudioBuffer(`assets/audio/${audioFile}.wav`);

      // create audioContext
      const audioContext = jsPsych.pluginAPI.audioContext();

      // create audio buffer source
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;

      // connect the source
      source.connect(audioContext.destination);

      // playing the audio
      source.start(0);
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  }
  // Displays the truck while the target car is moving towards the truck
  const truckPhase = {
    timeline: [
      // Phase 1.1: Truck signal is displayed and waits for user to make a decision
      // Phase ends when time runs out or user selects a lane
      {
        type: CustomHtmlKeyboardResponsePlugin,
        choices: ["KeyS", "KeyD", "KeyF", "KeyK", "KeyL", "Semicolon", "Space"],
        on_load: () => newGame.startPhase1_1(),
        on_finish: (data) => {
          data.name = "Phase1A_truckBlockingTarget";
          // Check if user choose a lane, this determines if we run phase 2 and 3
          if (data.response !== " ") {
            newGame.followTruckSignal = 1; // this is used in the game, so we keep it as variable
            data.keyCode = KEY_CODES[jsPsych.data.get().last(1).values()[0].response];
            data.driverMovedTruck = 2; //driver did not try to move truck
          } else {
            //case: driver pressed space to get rid of truck
            newGame.followTruckSignal = 0;
            // NGJ adds this code below because we want to see how often people press spacebar
            data.keyCode = 0; //we record spacebar presses as zeros
            if (newGame.truckMoves === 0) {
              //driver could NOT move truck even though they tried to move it
              data.driverMovedTruck = 0;
              playAudio("CG01_01_LockOn_V1");
            } else {
              //driver could move truck and also tried to move truck
              data.driverMovedTruck = 1;
            }
          }
          data.followTruckSignal = newGame.followTruckSignal; // we write this variable also into the datafile

          SCORE.assignScore(data);
        },
      },
      // Phase 1.2: Handles the truck movement off the screen, either:
      // 1) Moves the truck right and then off the highway (truckMoves === 1)
      // 2) Truck remains in place until the user responds
      // Conditional, occurs when the user doesn't chose a lane in phase 1.1
      // NOTE: After this we move on to phase2 as seen in mission 1
      //if truckMoves===1: specific keys (s, d, f, k, l, ;) are enabled for the participant to respond with.
      {
        timeline: [
          {
            choices: () =>
              newGame.truckMoves === 1
                ? "NO_KEYS"
                : ["KeyS", "KeyD", "KeyF", "KeyK", "KeyL", "Semicolon"],
            on_load: () => newGame.startPhase1_2(),
            on_finish: (data) => {
              data.name = "Phase1B_truckBlockingTarget";
              if (data.response !== undefined) {
                const key = data.response;
                data.keyCode = KEY_CODES[key];
              }
              if (data.response === " ") {
                data.keyCode = 0;
              }
              SCORE.assignScore(data);
            },
          },
        ],
        // Phase is only executed when the user did not choose a lane in phase 1.1
        conditional_function: () => newGame.followTruckSignal === 0,
      },
    ],
    conditional_function: () => {
      // truckPhase is only executed when BOTH:
      // 1) occurrenceTruck flag is 1
      // 2) User cannot avoid the truck (avoidTruck == 0) OR user has the ability but fails to do it (preventTruck == 0)
      return (
        newGame.occurrenceTruck !== 0 && (newGame.avoidTruck === 0 || newGame.preventTruck === 0)
      );
    },
  };

  const phase2and3 = {
    timeline: [
      // Phase 2: Start of CSI period, triggered by proximity zone initiation in previous phase
      // Terminates when CSI is over and it's time to show the license
      {
        // TODO: This is the exact same as mission 1 -> separate function?
        choices: "NO_KEYS",
        trial_duration: newGame.csi,
        on_load: () => newGame.startPhase2(),
        on_finish: (data) => {
          data.name = "licensePlateOn";
          data.timestamp = jsPsych.data.get().last(2).values()[0].time_elapsed;
          data.driverSpeed = newGame.driverCarSpeed;
          data.distanceToTarget = jsPsych.data.get().last(2).values()[0].distanceToTarget;
          SCORE.assignScore(data);
        },
      },

      // Phase 3: At the start of this phase, the license is displayed
      // Relies on the user to press one of the 6 correct key lanes to trigger the end of Phase 3
      {
        // TODO: This is the exact same as mission 1 -> separate function?
        choices: "NO_KEYS", // NOTE: Keyboard events are handled inside Game
        on_load: () => newGame.startPhase3(),
        on_finish: (data) => {
          data.name = "responseKeyPressed";
          data.keyCode = KEY_CODES[data.response] ?? null; // keyCode is null if no response is given
          data.responseTime =
            data.time_elapsed - jsPsych.data.get().last(2).values()[0].time_elapsed;
          data.driverSpeed = newGame.driverCarSpeed;

          SCORE.assignScore(data);
        },
      },
    ],
    // Phases 2 and 3 is executed when EITHER:
    // 1) The user choose a lane in phase 1.1
    // 2) The user did nopt choose a lane in phase 1.1 and the truck does not move
    conditional_function: () => {
      // TODO: This can be return !(...)
      if (
        newGame.followTruckSignal === 1 ||
        (newGame.followTruckSignal === 0 && newGame.truckMoves === 0)
      ) {
        return false;
      }
      return true;
    },
  };

  /** Build and return the trial */

  return {
    type: CustomHtmlKeyboardResponsePlugin,
    stimulus,
    data: { trialConfig },
    timeline: [
      //Phase 0: Board initializes, end of phase 0 is when target car appears
      {
        // TODO: This is the same as mission 1 -> separate function
        choices: "NO_KEYS",
        trial_duration: newGame.targetLaunchDelay,
        on_load: () => newGame.startPhase0(),
        on_finish: (data) => {
          data.name = "initializeTarget";
          data.driverSpeed = newGame.driverCarSpeed;
          data.distanceToTarget = newGame.driverDistanceToTarget;
          data.trialStart = {
            name: "trialStart",
            driverSpeed: newGame.driverCarSpeed,
            timestamp: jsPsych.data.get().last(2).values()[0].time_elapsed,
          };
          SCORE.assignScore(data);
        },
      },

      // Phase 1: Target car begins traveling towards driver car.
      // Optionally, truck may appear. And optionally, user may move to speed past truck.
      // Terminates when target car/driver car lock in proximity zone
      {
        // TODO: This is the same as mission 1 -> separate function
        choices: "NO_KEYS", // NOTE: Keyboard events are handled inside Game
        on_load: () => newGame.startPhase1(),
        on_finish: (data) => {
          data.name = "engageTarget";
          data.cueCarOn = {
            name: "cueCarOn",
            driverSpeed: newGame.driverCarSpeed,
            timestamp: jsPsych.data.get().last(1).values()[0].time_elapsed,
            distanceToTarget: newGame.driverDistanceToTarget,
            csi: newGame.csi,
          };
          // Crash - move on to the next timeline (e.g. skip the rest of this Game's phases)
          if (data.crashed) jsPsych.endCurrentTimeline();
          else data.crashed = false;

          SCORE.assignScore(data);
        },
      },

      //Optional phase1.5, where truck stuff will happen.
      truckPhase,

      //Optional phase2 and 3, depends on outcome of phase1.5
      phase2and3,

      // Phase 4: Once lane has been chosen in phase 3, phase 4 begins with the driver car moving to the user chosen lane
      // Terminates when the driver car reaches the chosen lane.
      {
        // TODO: This is the same as mission 1 -> separate function
        choices: "NO_KEYS",
        // Start the phase based on the lane chosen
        on_load: () => newGame.startPhase4(jsPsych.data.get().last(1).values()[0].keyCode),
        on_finish: (data) => {
          data.name = "driverInResponseLane";
          data.driverSpeed = newGame.driverCarSpeed;
          SCORE.assignScore(data);
        },
      },

      //Phase 5
      // Once driver is in chosen lane, the package will appear and travel down the correct lane. Simultaneously, the target car also moves down the highway until it disappears.
      // Once the package reaches the end of the highway, a score will show above the driver car, showing the points the driver car received in this game trial.
      {
        // TODO: This is the same as mission 1 -> separate function
        choices: "NO_KEYS",
        on_load: () => newGame.startPhase5(),
        on_finish: (data) => {
          data.name = "packageDisappears";
          data.driverSpeed = newGame.driverCarSpeed;
          data.emotion = newGame.emotion;
          data.packageInTargetLane = {
            timestamp: jsPsych.data.get().last(2).values()[0].time_elapsed,
            driverSpeed: newGame.driverCarSpeed,
            packageDistance: newGame.packageDistanceToTarget,
          };
          data.targetDisappears = {
            timestamp: newGame.targetCarDisappearTimestamp,
            driverSpeed: newGame.driverCarSpeed,
          };
          data.emotionReaction = {
            emotion: newGame.emotion,
            driverSpeed: newGame.driverCarSpeed,
            timestamp:
              jsPsych.data.get().last(1).values()[0].time_elapsed - newGame.feedbackDuration,
          };

          SCORE.assignScore(data);
        },
      },

      // Phase 7: Driver car travels back to the center of the highway
      // Triggered once user completes survey
      {
        // TODO: This is the same as mission 1 -> separate function
        choices: "NO_KEYS",
        on_load: () => newGame.startPhase7(),
        on_finish: (data) => {
          data.name = "driverAtCenter";
          data.driverToCenter = {
            timestamp: jsPsych.data.get().last(2).values()[0].time_elapsed,
            driverSpeed: newGame.driverCarSpeed,
          };
          data.driverSpeed = newGame.driverCarSpeed;
          SCORE.assignScore(data);
        },
      },
    ],
  };
}

/**
 * Builds the trial needed for mission one's task
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} metadata The global metadata of the experiment
 * @param {Object} trialConfig The task configuration used to build the trial
 * @param {String} stimulus The HTML stimulus to show
 * @param {Score} score The user's current score
 * @returns
 */
function buildMissionTwoMessage(jsPsych, metadata, trialConfig, stimulus) {
  const newGame = new Game(jsPsych, metadata, SCORE, trialConfig);
  return {
    type: CustomHtmlKeyboardResponsePlugin,
    stimulus,
    data: { trialConfig },
    choices: ["Space"],
    on_load: () => {
      // Initialize board and display the agent file
      newGame.startPhase0();
      newGame.startPhase6();
    },
    on_finish: (data) => SCORE.assignScore(data),
  };
}

/**
 * Builds the trial needed for mission one's feedback
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} trialConfig The task configuration used to build the trial
 * @param {String} missionMinScore Per mission minimum score to continue
 * @param {String} missionMaxCrash Per mission max crashes allowed
 * @returns
 */
function buildMissionTwoFeedback(jsPsych, trialConfig, missionMinScore, missionMaxCrash) {
  return {
    data: { trialConfig },
    timeline: [
      // Display the total rewards
      ...buildFeedbackTrial(
        jsPsych,
        SCORE,
        missionMinScore,
        missionMaxCrash,
        "assets/images/postgame/FeedbackScreen_Mission2.svg"
      ),
      // Display the user's achievements
      buildAchievementsTrial("assets/images/postgame/achievementScreen_mission2.svg"),
    ],
  };
}

/**
 * Builds the trial needed for mission one's task
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} metadata The global metadata of the experiment
 * @param {Object} trialConfig The task configuration used to build the trial
 * @param {String} stimulus The HTML stimulus to show
 * @returns
 */
function buildMissionTwoSurvey(jsPsych, metadata, trialConfig, stimulus) {
  const newGame = new Game(jsPsych, metadata, SCORE, trialConfig);

  return {
    type: CustomHtmlKeyboardResponsePlugin,
    stimulus,
    data: { trialConfig },
    timeline: [
      //Phase 0: Board initializes, end of phase 0 is when target car appears
      {
        choices: "NO_KEYS",
        trial_duration: newGame.targetLaunchDelay,
        on_load: () => newGame.startPhase0(),
      },
      // Phase 6: Headquarter Survey
      {
        on_load: () => newGame.startPhase6(),
        choices: ["KeyS", "KeyD", "KeyF", "KeyK", "KeyL", "Semicolon"],
        trial_duration: newGame.headquarterMessageDuration,
        on_finish: (data) => {
          data.name = "messageFromHQ";
        },
      },
    ],
  };
}
